.container {
  flex: 1;
  position: relative;
}

.containerInstructions {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  max-width: 70%;
  align-self: center;
  padding: 80px 0px;
}

.welcomeText {
  font-size: var(--xxl);
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--primary);
  text-align: center;
}

.descriptionText {
  font-size: var(--l);
  text-align: center;
  margin-bottom: 16px;
  color: var(--primary-text);
}

.divider {
  margin: 40px 0px;
}

.titleRule {
  font-size: var(--xxl);
  font-weight: 600;
  color: var(--primary-text);
  margin-bottom: 16px;
}

.containerItemList {
  margin-bottom: 16px;
}

.numberList {
  font-size: 18px !important;
  color: var(--primary-text) !important;
  margin-bottom: 6px;
}

.textList {
  font-size: var(--l) !important;
  color: var(--fourth) !important;
  margin-left: 16px !important;
  margin-bottom: 6px;
}

.noteText {
  color: var(--fourth);
}

.segmented {
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  transition: all 0.3s ease-in-out; /* Transición suave */
}

.segmentedFixed {
  position: fixed;
  top: 105px;
  background-color: rgb(73, 73, 73);
  padding: 12px;
  left: 0;
  display: flex;
  justify-content: center;
  width: -webkit-fill-available;
  transition: all 0.3s ease-in-out; /* Transición suave */
}
.mobileSegmented {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu {
  color: var(--primary-text);
  font-size: 20px;
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.antdSegmented {
  width: -webkit-fill-available;
  margin-top: 10px;
}
