.fixtureContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-text);
  gap: 16px;
  align-self: center;
  user-select: none;
  max-width: 50%;
  user-select: none;
}
.golden {
}

.iconFixture {
  cursor: pointer;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconFixture:hover {
  color: var(--primary);
}

.fixturesText {
  font-size: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.fixturesText:hover {
  opacity: 0.8;
}

.fixturesTextOnlyView:hover {
  opacity: 1;
  cursor: default;
}

@media (max-width: 768px) {
  .fixturesText {
    font-size: 16px;
    font-weight: 600;
  }
}
