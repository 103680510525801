.modal {
}

.content {
  min-width: 500px;
  user-select: none;
}
.header {
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  background: linear-gradient(145deg, #121212, #1c1c1c);
  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}

.coins {
  font-size: 20px;

  font-weight: bold;
  align-items: center;
  gap: 16px;
  i {
    font-size: 40px;
    color: var(--primary);
  }
}

.infoText {
  font-size: 14px;
  color: #b0b0b0; /* Color gris claro */
  margin-bottom: 24px;
  text-align: center;
}

.spiner {
  margin: 20px 0px;
}

.divider {
  margin-top: 24px;
  margin-bottom: 8px;
}

.aclaration {
  font-size: 12px;
  color: var(--tertiary-text);
  text-align: center;
  margin-top: 24px;
}
.paymentMethods {
  font-size: 30px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.logo {
  width: 30px; /* Tamaño del logo */
  height: auto;
  opacity: 0.8;
}

.visa {
  background-color: rgba(255, 255, 255, 0.9);
  height: 20px;
  opacity: 0.8;
}

.mastercard {
  background-color: rgba(255, 255, 255, 0.9);
  height: 20px;
  width: 40px;
  opacity: 0.8;
}

.american {
  height: 20px;
  width: 40px;
  opacity: 0.8;
}

.description {
  font-size: 12px;
  color: var(--tertiary-text);
}

.terms {
  font-style: italic;
  text-decoration: underline;
}

.terms:hover {
  cursor: pointer;
  opacity: 0.8;
}
