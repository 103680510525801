.container {
  align-items: center;
  max-width: 800px;
}

.title {
  color: var(--primary-text);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.description {
  color: var(--primary-text);
  font-size: 16px;
  font-size: 500;
  text-align: center;
}
.card {
  margin: 60px 0px;
  max-width: 600px;
}
.input {
  min-width: 400px;
  height: 44px;
}

.form {
  margin-top: 20px;
}

.button {
  margin-top: 40px;
}
.divider {
  margin: 20px 0px;
}
.note {
  text-align: left;
  color: var(--tertiary-text);
  margin-bottom: 8px;
}

.depositAmount {
  color: var(--primary);
  text-align: center;
  font-size: 16px;

  margin-bottom: 8px;
}

.errorAmount {
  color: #f5222d;
  font-size: 14px;
  font-weight: 400;
  margin-top: -10px;
}

.containerAmount {
  margin-top: -10px;
  font-weight: 600;
}
