.tableContainer {
  width: 90%;
  overflow-x: auto; /* Para scroll horizontal si la tabla es más ancha que el contenedor */
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}

.footer {
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 14px;
  margin-top: 8px;
  background: linear-gradient(145deg, #121212, #1c1c1c);
  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  align-self: center;
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    /* Resplandor interior */ 0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}

.showMoreTickets {
  cursor: pointer;
  color: var(--primary);
}

.showMoreTickets:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .tableContainer {
    margin: 0px 8px 80px 8px;
    width: -webkit-fill-available;
  }
}
