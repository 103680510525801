.tournamentText {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: var(--primary-text);
  gap: 12px;
  cursor: pointer;
  align-self: center;
  padding: 12px;
  transition: border 0.3s;
  border: 1px solid transparent;
  border-radius: 0px;
  width: -webkit-fill-available;
  user-select: none;
}
.tournamentName {
  font-family: 'Oswald', sans-serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
}

.tournamentText svg {
  width: 20px;
  height: 20px;
}

.tournamentText:hover {
  border: 1px solid grey;
  border-radius: 0px;
  border-top-left-radius: 8px;
}

.tournamentTextGolden {
}

.tournamentTextGolden:hover {
  opacity: 0.8;
  border: 1px solid var(--primary-text);
}

.tournamentTextOnlyView:hover {
  cursor: default;
  border: 1px solid transparent;
  opacity: 1;
}

@media (max-width: 768px) {
  .tournamentText {
    justify-content: center;
    padding: 8px;
  }
  .tournamentName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 600;
  }

  .tournamentText svg {
    width: 20px;
    height: 20px;
  }
}
