.divider {
  display: flex;
  background-color: var(--tertiary-text);
}

.horizontal {
  width: 100%;
  height: 1px;
}

.vertical {
  width: 1px;
  height: 100%;
}
