.backgroundImg {
  background-image: url('../../../assets/history.png'); /* Ruta de tu imagen de fondo */
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.backgroundImg::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.content {
  padding: var(--xxxl);
  border-radius: 16px;
  margin: -20px -24px;
  border: 1px solid silver;
  color: var(--primary-text);
  font-size: var(--l);
  width: 700px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.bodytext {
  gap: 10px;
}

.closeIcon {
  color: var(--primary-text);
}

.title {
  font-weight: bold;
  font-size: var(--xl);
  text-align: center;
  margin-bottom: 16px;
}

.checkbox {
  margin: 24px 0px 8px 0px;
  gap: 8px;
  cursor: pointer;
}

.checkbox:hover {
  opacity: 0.8;
}

.buttonConfirm {
  margin-bottom: 8px;
}

.prizeContainer {
  width: -webkit-fill-available;
  height: 30px;
  position: relative;
  border-radius: 16px;
  color: var(--primary-text);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 16px;
  margin-top: 16px;
}
.prizeText {
  position: relative;
  white-space: nowrap;
  font-size: var(--l);
  z-index: 2;
}
.prizeAmount {
  position: relative;
  z-index: 2;
  font-size: var(--xl);
}
.prizeContainer::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  border-radius: 16px;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@media (max-width: 768px) {
  .content {
    width: -webkit-fill-available;
    padding: var(--m);
  }
}
