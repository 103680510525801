.containerHeader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--primary-text);
}
.containerHeaderHome {
  padding: 30px 24px 24px 24px;
}

.containerHeaderHomeScroll {
  padding: 24px;
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 4;
}

.containerHeaderRest {
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 4;
  position: relative;
}

.containerHeaderRestScroll {
  padding: 24px;
  position: fixed;
  z-index: 4;
  top: 0;
  width: -webkit-fill-available;
  background-color: rgba(0, 0, 0, 0.85);
  min-height: 90px;
}

.headerText {
  font-weight: 600;
  cursor: pointer;
  border-radius: 12px;
  padding: 8px;

  border: 1px solid transparent;
  transition: border 1s;
}

.headerText:hover {
  color: var(--primary);

  border: 1px solid var(--primary);
  border-radius: 12px;
  padding: 8px;
}

.headerTextSelected {
  color: var(--primary);
}

.headerTextSelected:hover {
  color: var(--secondary-text);
  border: 1px solid transparent;
}

.dividerWidth {
  width: 33%;
  display: flex;
  justify-content: center;
}

.containerLogo {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.logo {
  width: 60px;
}
.logoTitle {
  font-family: 'BebasNeue', sans-serif;
  text-transform: uppercase;
  color: var(--primary-text);
  letter-spacing: 5px;
  font-weight: bold;
  font-size: var(--l);
}

.mobileMenu {
  display: none;
}
@media (max-width: 768px) {
  .navigationMenu {
    display: none;
  }

  .authHeader {
    display: none;
  }
  .mobileMenu {
    display: flex;
    gap: 24px;
  }
  .containerHeader {
    justify-content: space-between;
  }

  .containerHeaderHome {
    padding: 16px;
  }

  .containerLogo {
    overflow: hidden;
    width: -webkit-fill-available;
    justify-content: flex-start;
  }
  .logo {
    width: 50px;
  }
}
