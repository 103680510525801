.containerResultSection {
  color: var(--primary-text);
  padding: 80px 80px 200px 80px;
  background-color: var(--dark);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerPositions {
  margin-top: 80px;
  align-items: center;
  gap: 20px;
}
.title {
  font-size: var(--xxl);
  font-weight: bold;
}
.buttonGroup {
  min-width: 300px;
}
.containerTables {
  margin-top: 40px;
  width: 600px;
}

.divider {
  background-color: rgb(72, 71, 71);
}

@media (max-width: 768px) {
  .containerResultSection {
    padding: 24px 0px;
    flex-direction: column;
    gap: 48px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .containerPositions {
    margin-top: 10px;
  }
  .buttonGroup {
    min-width: 150px;
  }
  .containerTables {
    width: -webkit-fill-available;
    margin-top: 0px;
  }
}
