.container {
  background-color: rgba(20, 20, 20, 0.9);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.input {
  height: 60px;
}

.responseErrorText {
  color: var(--error-text);
  background-color: rgba(255, 0, 0, 0.2);
  display: flex;
  padding: var(--xxxl);
  border-radius: var(--m);
}

.button {
  padding: 26px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.form {
  min-width: 400px;
}

@media (max-width: 768px) {
  .form {
    min-width: auto;
  }
  .title {
    white-space: nowrap;
    font-size: 24px !important;
  }
}
