.container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  user-select: none;
}

.mpButton {
  background: linear-gradient(
    90deg,
    #014468,
    #005f91,
    #005f91,
    #014468
  ); /* Degradado azul */
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  border: none;
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px; /* Espacio entre logo y texto */
  justify-content: center;
  transition:
    transform 0.3s ease,
    background-color 0.3s ease; /* Suaviza hover y clic */
}

.mpButton:hover {
  transform: scale(1.01); /* Agrandar ligeramente al pasar el mouse */
}

.mpButton:active {
  transform: scale(0.99); /* Reducir ligeramente al hacer clic */
}

.logo {
  width: 30px; /* Tamaño del logo */
  height: auto;
}

.text {
  text-align: center;
  font-size: 11px;
  color: var(--tertiary-text);
  margin-top: 4px;
}
