.goBack {
  color: var(--tertiary);
  font-size: var(--xs);
  cursor: pointer;
  margin-bottom: var(--xl);
  margin-top: var(--xxs);
  display: flex;
  justify-content: center;
}

.goBack:hover {
  color: var(--primary);
}

.responseSuccessText {
  color: var(--success-text);
  background-color: rgba(0, 128, 0, 0.2);
  display: flex;
  padding: var(--m);
  border-radius: var(--m);
}

.responseErrorText {
  color: var(--error-text);
}

.description {
  color: var(--primary-text);
}

.button {
  margin-top: 40px;
}
