.containerTagNames {
  align-items: center;
  gap: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  padding: 12px 16px;

  overflow-x: auto;
  overflow-y: hidden;
}

/* Para navegadores basados en Webkit (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  height: 4px; /* Ajusta el ancho de la barra de scroll horizontal */
}

.tag {
  font-size: 14px;
  padding: 4px 16px;
  height: auto;
  color: var(--tertiary-text);
  border: 1px solid var(--tertiary-text);
  white-space: nowrap; /* Evita saltos de línea */
  border-radius: 8px;
  width: -webkit-fill-available;
}

.tag:hover {
  color: var(--primary-text);
  border-color: var(--primary-text);
}

.golden {
  color: var(--primary-text);
  border: 1px solid var(--dark);
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.4);
}

.checkedGolden {
  background-color: var(--dark);
  color: var(--primary-text);
}
.checkedGolden:hover {
  color: var(--primary-text);
  opacity: 0.8;
}

.checked {
  background: var(--primary-gradient);
  color: var(--dark); /* Cambiamos el color del texto a blanco */
  border-color: var(--dark); /* Cambiamos el color del borde a amarillo */
  font-weight: 600;
  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    /* Resplandor interior */ 0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}

.checked:hover {
  color: var(--dark); /* Cambiamos el color del texto a blanco */
  border-color: var(--dark); /* Cambiamos el color del borde a amarillo */
  opacity: 0.9;
}

@media (max-width: 768px) {
  .containerTagNames {
    border-top: 1px solid rgba(255, 255, 255, 0.3); /* Línea blanca tenue */
  }
}
