.content {
  min-width: 500px;
  min-height: 150px;
  gap: 8px;
}

.closeIcon {
  color: var(--primary-text);
}

.modalTitle {
  color: var(--primary-text);
  font-weight: bold;
  font-size: var(--xl);
  text-align: center;
  margin-bottom: 16px;
}

.emptyText {
  color: var(--primary-text);
  font-size: var(--xl);
  text-align: center;
}

.description {
  color: var(--primary-text);
  font-size: var(--m);
  text-align: center;
  margin-top: 12px;
}

@media (max-width: 768px) {
  .content {
    min-width: auto;
  }
  .fixtureModal {
    top: 8px;
  }
}
