.backgroundImg {
  background-image: url('../../../assets/history.png'); /* Ruta de tu imagen de fondo */
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.backgroundImg::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.content {
  padding: var(--xxxl);
  border-radius: 16px;
  margin: -20px -24px;
  border: 1px solid silver;
  color: var(--primary-text);
  font-size: var(--l);
  width: 700px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.tournamenttext {
  font-size: var(--xxl);
  text-align: center;
  font-weight: bold;
  line-height: normal;
  z-index: 1;
}

.title {
  font-size: var(--xxl);
  text-align: center;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 16px;
}

.checkbox {
  margin: 8px 0px;
  gap: 8px;
  cursor: pointer;
}
.bold {
  font-weight: bold;
}

.tickettext {
  text-decoration: underline;
  cursor: pointer;
}

.infotext {
  font-size: var(--s);
  font-style: italic;
  margin: 16px 0px;
}

.subtitle {
  margin: 8px 0px;
}

.prizetext {
  flex-wrap: wrap;
  align-items: center;
  font-size: var(--xl);
  justify-content: center;
  margin: 8px 0px;
}

.multiplePrizetext {
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  justify-content: center;
  margin: 8px 0px;
  font-size: var(--xl);
}
.multiplePrize {
  display: flex;
  gap: 8px;
  font-weight: 500;
  cursor: pointer;
  color: var(--primary-text);
}

.img {
  width: 300px;
  align-self: center;
  margin-bottom: 16px;
  margin-top: 8px;
}

.closeIcon {
  color: var(--primary-text);
}

.button {
  height: 44px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.winerText {
  align-items: center;
  gap: 8px;
}

@media (max-width: 768px) {
  .content {
    width: auto;
    font-size: var(--m);
    padding: var(--m);
  }
  .tournamenttext {
    font-size: var(--xl);
  }

  .title {
    font-size: var(--xl);
    margin-bottom: 8px;
  }
  .img {
    width: 150px;
    align-self: center;
    margin-bottom: 16px;
  }
  .resttext {
    font-size: var(--s);
    text-align: justify;
  }
  .infotext {
    text-align: justify;
  }
  .modal {
    top: 0;
  }
  .button {
    height: 32px;
  }
  .multiplePrizetext {
    font-size: var(--l);
  }
  .prizetext {
    font-size: var(--l);
  }
}
