.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.containerMatches {
  align-items: center;
  overflow-y: auto;
  width: -webkit-fill-available;
  gap: 12px;
  flex: 1;
}

.header {
  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
  border-radius: 8px;
  margin-bottom: 8px;
  max-width: 550px;
  overflow: hidden;
}

.goldenHeader {
  border-color: var(--dark);
  background-color: rgba(0, 0, 0, 0.7);
}

.containerTournamentAndFixture {
  height: 100%; /* Asegúrate de que el padre tenga una altura */

  justify-content: space-between;
}

.divid {
  /* Efecto de borde luminoso */
  border-left: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
}

.dividerGolden {
  border-left: 1px solid var(--dark);
}

@media (max-width: 768px) {
  .containerMatches {
    width: -webkit-fill-available;
  }

  .containerTournamentAndFixture {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header {
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.3); /* Línea blanca tenue */
    box-shadow:
      inset 0px 1px 4px rgba(255, 255, 255, 0.3),
      0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
  }
}
