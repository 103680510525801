.container {
  font-size: 16px;
  color: var(--primary-text);
  max-width: 550px;
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 12px;
  gap: 8px;

  margin: 12px 0px 8px 0px;
}

.active {
  background-color: rgba(0, 0, 0, 0.6);
  border-color: var(--dark);
}

.titleContainer {
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  i {
    color: var(--primary);
  }
}

.title {
  text-align: center;
  font-weight: bold;
  color: var(--primary);
}

.description {
  font-size: 14px;
  text-align: center;
}

.importantText {
  font-size: 12px;
  text-align: center;
}

.containerSwitch {
  display: flex;
  justify-content: center;
}
