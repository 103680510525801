@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

:root {
  max-width: 100vw;
  min-height: 100vh;
  font-size: 16px;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  font-weight: 400;

  /* tokens */
  --primary: #f9ba15;
  --secondary: #edeac2;
  --tertiary: #c99400;
  --fourth: rgb(194, 190, 190);
  --dark: #0a1014;
  --grey: #182329;
  --mid-grey: #9b9b9b;
  --light-grey: #ebebeb;
  --white: #ffffff;
  --black: #0000;
  --background: #ffffff;
  --error: red;
  --success: rgb(62, 224, 62);
  --red: #ff0000;
  /* Sizes*/
  --xxs: 0.5rem; /* 8px*/
  --xs: 0.75rem; /* 12px*/
  --s: 0.875rem; /* 14px*/
  --m: 1rem; /* 16px*/
  --l: 1.125rem; /* 18px*/
  --xl: 1.5rem; /* 24px*/
  --xxl: 2rem; /* 32px*/
  --xxxl: 3rem; /* 48px */
  --xxxxl: 5rem; /* 80px */

  /* Gradients */
  --primary-gradient: linear-gradient(
    145deg,
    #f9d423,
    #f6a623,
    #e6ac00,
    #d4af37
  );
  --dark-gradient: linear-gradient(145deg, #1f1f1f, #2c2c2c, #1a1a1a, #000000);
  --grey-gradient: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3)
  );
  --white-gradient: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5),
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0.5)
  );

  /* texts colors */
  --primary-text: var(--white);
  --secondary-text: var(--secondary);
  --tertiary-text: #797e87;
  --success-text: var(--success);
  --error-text: var(--error);
  --disabled-text: var(--mid-grey);
  --dark-text: (var(--dark));
  /* text weight  */
  --bold-text: 800;
  --medium-text: 600;
  --normal-text: 400;

  --checkboxSize: 1;
}
#root {
  max-width: 100vw;
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  font-weight: 800;
  color: var(--primary-text);
  text-decoration: inherit;
}

p {
  margin: 0px;
}

body {
  margin: 0;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--dark);
  position: relative;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

.ant-btn-primary {
  font-weight: 900;
  letter-spacing: 1px;
  color: var(--dark);
}

.ant-btn-primary:disabled {
  background-color: var(--primary);
  opacity: 0.5;
}

::-webkit-scrollbar {
  width: 17px;
  background-color: rgb(0, 0, 0);
}

::-webkit-scrollbar-track {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 2px solid rgba(255, 255, 255, 0.3);
}

/* Estilos para el scrollbar */
::-webkit-scrollbar {
  width: 17px; /* Ancho del scrollbar */
}

.ant-modal {
  display: flex;
  justify-content: center;
  max-width: -webkit-fill-available;
}

.ant-tooltip .ant-tooltip-inner {
  color: var(--primary-text);
}

.ant-upload-drag {
  color: var(--primary-text) !important;
}

.ant-upload-drag:hover p.ant-upload-text {
  color: var(--primary) !important;
}

p.ant-upload-text {
  color: var(--primary-text) !important;
}

p.ant-upload-hint {
  color: var(--primary-text) !important;
}

.ant-form-item-tooltip {
  color: var(--primary-text) !important;
}
.anticon-delete {
  color: var(--primary-text) !important;
}

.ant-float-btn-primary:hover {
  opacity: 0.9;
}

.ant-select-selector {
  color: var(--primary-text) !important;
  border: 1px solid var(--primary-text);
}
.ant-select-selection-item {
  color: var(--primary-text) !important;
}
.ant-select-item-option-content {
  color: var(--primary-text) !important;
}

.ant-select-dropdown {
  background-color: var(--dark);
  border: 1px solid var(--primary-text);
}

.ant-switch {
  background-color: var(--tertiary-text);
}

.ant-switch:not(.ant-switch-checked):hover {
  background-color: rgba(229, 158, 17, 0.3);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 0 1000px #333333; /* Fondo oscuro aplicado al autofill */
}

.ant-notification-notice-wrapper {
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 6px; /* Ancho del scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 2px solid rgba(255, 255, 255, 0.3);
  }

  .ant-modal-root .ant-modal {
  }
  .ant-modal-content {
    width: calc(100vw - 16px) !important;
    min-width: calc(100vw - 16px) !important;
  }
  .ant-modal-root .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .ant-drawer-content {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: var(--primary-text);
  }

  .ant-drawer-body {
    display: flex;
  }
  .ant-alert {
    font-size: 12px;
  }

  .ant-tag {
    margin-inline-end: 0px;
  }

  .ant-tour {
    width: -webkit-fill-available;
  }
}
