.container {
  background-color: rgba(20, 20, 20, 0.1);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: var(--tertiary-text);
  gap: var(--xxl);
  padding: 40px 0px;
  padding-bottom: 150px;
  i {
    cursor: pointer;
  }
  i:hover {
    color: var(--primary);
  }
}

.card {
  background: var(--dark-gradient);
  border-radius: 16px;
  padding: var(--xl);
  font-size: var(--l);
  min-width: 700px;
  align-items: center;

  box-shadow: 0px 5px 10px 0px rgba(97, 97, 97, 0.35);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(97, 97, 97, 0.35);
  -moz-box-shadow: 0px 5px 10px 0px rgba(97, 97, 97, 0.35);
}
.title {
  color: var(--primary-text);
  font-size: 24px;
  text-align: center;
}
.description {
  color: var(--tertiary-text);
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}

.subTitle {
  color: var(--primary-text);
  font-size: 20px;
}
.containerSocial {
  gap: 34px;
  i {
    font-size: 60px;
  }
}

.containerEmail {
  align-items: center;
  gap: 16px;
  font-size: 26px;
  i {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .card {
    width: -webkit-fill-available;
    padding: 16px;
    gap: 4px;
  }
  .container {
    gap: 16px;
    padding: 16px;
  }
}
