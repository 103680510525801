.content {
  padding: var(--xxxl);
  border-radius: 16px;
  margin: -20px -24px;
  border: 1px solid silver;
  z-index: 1;
  position: relative;
  overflow: hidden;
  background: var(--dark-gradient);
  min-width: 400px;
  padding: 40px;
}
.modal {
  top: 16px;
}

.header {
  color: var(--primary-text);
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 26px;
}

.closeIcon {
  color: var(--primary-text);
  cursor: pointer;
}
.closeIcon:hover {
  color: var(--primary);
}

@media (max-width: 768px) {
  .content {
    padding: 40px 8px;
    min-width: auto;
  }
  .modal {
    top: 0;
  }
}
