.containerItemTicket {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  border: 1px solid rgb(126, 125, 125);
  align-items: center;
  padding: 6px 12px;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: -3px 7px 7px -2px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: -3px 7px 7px -2px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: -3px 7px 7px -2px rgba(255, 255, 255, 0.1);
  user-select: none;
  height: 40px;
  cursor: pointer;
  font-weight: bold;
}

.containerItemTicket:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2)
  );
}

.selectedItem {
  pointer-events: none;
  border-color: var(--dark);
  color: var(--dark);
  background: linear-gradient(145deg, #f9d423, #f6a623, #e6ac00, #d4af37);
}

.nameText {
  min-width: 30%;
}

.tag {
  min-width: 120px;
  pointer-events: none;
}

.inProgress {
  background: linear-gradient(
    145deg,
    rgba(34, 78, 35, 0.8),
    rgba(73, 113, 75, 0.8)
  );
  border-color: rgba(34, 78, 35, 0.8);
}

.finished {
  background: linear-gradient(
    145deg,
    rgba(66, 66, 66, 0.8),
    rgba(117, 117, 117, 0.8)
  );
  border-color: rgba(66, 66, 66, 0.8);
}

.toStart {
  background: linear-gradient(
    145deg,
    rgba(130, 98, 19, 0.8),
    rgba(148, 135, 15, 0.8)
  );
  border-color: rgba(130, 98, 19, 0.8);
}

.created {
  background: linear-gradient(
    145deg,
    rgba(92, 75, 75, 0.8),
    rgba(177, 156, 156, 0.8)
  );
  border-color: rgba(92, 75, 75, 0.8);
}

@media (max-width: 768px) {
}
