.containerFooter {
  color: var(--primary-text);
  font-size: var(--m);
  border-top: 1px solid var(--dark);
  height: 300px;
  overflow: hidden;
}

.containerFooterImg {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  font-weight: bold;
  flex-direction: column;
  gap: 24px;
}
.containerColumnsFooter {
  flex: 1;
  justify-content: space-between;
}

.backgroundFooterImg {
  background-position: bottom;
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.backgroundFooterImg::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  bottom: 0;
}

.logoContainer {
  background-color: rgba(100, 97, 97, 0.2);
  padding: 16px;
}

.logo {
  width: 40px;
}
.title {
  font-size: 26px;
}

.icon {
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.icon:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.containerIcons {
  gap: 24px;
  padding-top: 24px;
}

.copyRight {
  padding-left: 24px;
}
.containerLinks {
  gap: 12px;
  color: var(--tertiary-text);
  justify-content: center;
  font-size: 12px;
}

.text {
  display: flex;
  color: var(--tertiary-text);
  justify-content: center;
  font-size: 12px;
  margin-bottom: 10px;
}

.link:hover {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}

.divider {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    /* Resplandor interior */ 0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}
@media (max-width: 768px) {
  .containerFooter {
    height: auto;
  }
  .containerIcons {
    gap: 16px;
    padding: 16px 0px;
  }
  .footerText {
    gap: 16px;
  }
  .containerText {
    margin: 16px 8px;
  }
  .text {
    text-align: center;
  }

  .title {
    font-size: 20px;
  }

  .copyRight {
  }

  .containerLinks {
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    margin-left: 20px;
  }
}
