.content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: var(--xxxl);
  border-radius: 16px;
  margin: -20px -24px;
  border: 1px solid silver;
  color: var(--primary-text);
}

.label {
  color: var(--primary-text);
}

.closeIcon {
  color: var(--primary-text);
}

.title {
  font-size: var(--xl);
  text-align: center;
}

.text {
  font-size: var(--m);
  margin: 8px;
}

.formText {
  margin: 8px;
}

.checkbox {
  margin: 8px;
  gap: 8px;
}

.textWaring {
  margin: 4px;
  font-style: italic;
  color: var(--primary-text);
}
.alert {
  margin-top: 16px;
  margin-bottom: 16px;
}

.textPrize {
  flex-wrap: wrap;
  align-items: center;
  font-size: var(--m);
  color: var(--fourth);
}
.prize {
  display: flex;
  gap: 8px;
  font-size: var(--l);
  font-weight: 500;
  cursor: pointer;
  color: var(--primary-text);
}
.multiplePrize {
  display: flex;
  gap: 8px;
  font-size: var(--l);
  font-weight: 500;
  cursor: pointer;
  color: var(--primary-text);
}
.form {
  margin-top: 16px;
}
@media (max-width: 768px) {
  .content {
    padding: 40px 8px;
  }
  .modal {
    top: 0;
  }
  .prize {
    font-size: var(--m);
  }
  .multiplePrize {
    font-size: var(--m);
  }
  .title {
    font-size: var(--l);
  }
}
