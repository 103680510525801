.tag {
  padding: 2px 16px;
  cursor: pointer;
  font-size: var(--m);
  line-height: var(--m);
  color: var(--primary-text);
  border: 1px solid var(--primary-text);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease; /* Agregamos una transición para suavizar el cambio */
}

.tag:hover {
  color: var(--primary); /* Cambiamos el color del texto a blanco */
  border-color: var(--primary); /* Cambiamos el color del borde a amarillo */
  font-weight: 600;
}

.small {
  padding: 4px;
  font-size: 12px;
  line-height: 12px;
}
