.containerFixtureItem {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: rgb(168, 168, 168);
  user-select: none;
  margin-bottom: 0px;
  max-width: 600px;
  min-width: 550px;
  border-top: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
}
.golden {
  border-top-color: var(--dark);
}

.containerTeam {
  display: flex;
  font-weight: 700;
  align-items: center;
  padding: 6px 0px;
  width: -webkit-fill-available;
  justify-content: center;
  font-size: 14px;
}

@media (max-width: 768px) {
  .containerFixtureItem {
    width: -webkit-fill-available;
    max-width: none;
    min-width: auto;
    margin-top: 4px;
  }
  .containerTeam {
    font-size: 12px;
  }
}
