.content {
  background-color: rgba(0, 0, 0, 0.9);
  padding: var(--xxxl);
  border-radius: 16px;
  margin: -20px -24px;
  border: 1px solid silver;
  color: var(--primary-text);
}
.closeIcon {
  color: var(--primary-text);
}
.header {
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.title {
  font-family: 'BebasNeue', sans-serif;
  text-transform: uppercase;
  color: var(--primary-text);
  letter-spacing: 5px;
  font-weight: bold;
  font-size: var(--l);
}

.text {
  font-size: var(--m);
  text-align: justify;
  margin-bottom: 16px;
}

.close {
  display: flex;
  justify-content: center;
  font-weight: 200;
  margin-top: 8px;
}
.close:hover {
  opacity: 0.8;
  cursor: pointer;
}
.checkbox {
  margin-bottom: 16px;
  gap: 8px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .content {
    padding: 16px 8px;
  }
  .header {
    gap: 8px;
  }
  .title {
    letter-spacing: 2px;
    font-size: var(--l);
    color: var(--primary);
  }
  .logo {
    display: none;
  }
  .text {
    padding: 8px;
  }

  .modal {
    top: 55px;
  }
}
