.title {
  color: var(--primary-text);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.description {
  color: var(--primary-text);
  font-size: 16px;
  font-size: 500;
  text-align: center;
}

.container {
  padding-bottom: 80px;
}
