.loginText {
  cursor: pointer;
}

.registerText {
  border: 1px solid var(--dark);
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  background-color: var(--primary);
  color: var(--dark);
  font-weight: 600;
}
.registerButton {
  font-size: 16px !important;
  padding: 6px 12px !important;
}
.loginText:hover {
  color: var(--primary);
}
.registerText:hover {
  opacity: 0.9;
}

.dividerWidth {
  width: 33%;
  display: flex;
  justify-content: center;
}

.containerCredits {
  align-items: center;
  padding: 8px;

  i {
    font-size: 25px;
    color: var(--primary);
  }
}

.dropdown {
  color: var(--primary-text);
  display: flex;
  gap: 8px;
  align-items: center;

  font-weight: 800;
  user-select: none;
}

.up {
  display: none;
}
.down {
  display: block;
}

.dropdown:hover {
  color: var(--primary);
  cursor: pointer;
  .up {
    display: block;
  }
  .down {
    display: none;
  }
}
