.tableRow {
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.tableRow:hover {
  background: var(--grey-gradient);
}

.leftText {
  text-align: left !important;
}

.ticketIcon {
  color: gold;
  margin-left: 16px;
  font-size: 18px;
}

.first {
  background: var(--primary-gradient);
  color: var(--dark);
  font-weight: bold;
  .ticketIcon {
    color: var(--dark);
  }
}

.first:hover {
  background: linear-gradient(
    145deg,
    rgba(249, 212, 35, 0.9) 0%,
    rgba(247, 183, 55, 0.6) 35%,
    rgba(230, 166, 35, 0.9) 70%,
    rgba(212, 175, 55, 0.3) 100%
  );
}

.first::after {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3)
  );

  transform: skewX(-30deg);
  animation: shine 3s infinite linear;
  pointer-events: none; /* No afecta interacciones */
}

@keyframes shine {
  0% {
    transform: translateX(-200%) skewX(-30deg);
  }
  100% {
    transform: translateX(200%) skewX(-30deg);
  }
}

@media (max-width: 768px) {
  .tableRow {
    height: 50px;
  }

  .leftText {
    overflow: hidden; /* Oculta el contenido que excede el ancho */
    text-overflow: ellipsis; /* Agrega los puntos suspensivos (...) */
    max-width: 100px; /* Establece un ancho máximo para la celda */
  }
}
