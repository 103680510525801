.containerItem {
  color: var(--tertiary-text);
  gap: 8px;
  justify-content: flex-start;
  font-size: var(--l);
  color: inherit;
}

.point {
  background-color: var(--tertiary-text);
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  color: transparent;
  margin-top: 8px;
}
