.containerFixtureItem {
  background-color: rgba(0, 0, 0, 0.3);
  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.3),
    0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */

  border-radius: var(--s);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px;
  font-size: var(--m);
  line-height: var(--m);

  font-weight: 600;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-text);
  user-select: none;

  max-width: 600px;
  min-width: 550px;
  padding: 8px 24px;
  gap: 24px;
}

.containerFixtureItemGolden {
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid var(--dark);
}

.containerTeam {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  width: -webkit-fill-available;
}

.teamText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.containerStatus {
  border: 1px solid var(--mid-grey);
  border-radius: 16px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  color: var(--mid-grey);
  background-color: rgba(0, 0, 0, 0.6);
  font-size: var(--s);
  line-height: var(--s);
  font-weight: 600;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  user-select: none;

  white-space: nowrap;
  overflow: hidden;
  width: -webkit-fill-available;
  gap: 8px;

  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.3),
    0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}
.containerStatusGolden {
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(196, 196, 196);
  border: none;
  box-shadow: -3px 7px 7px -2px rgba(0, 0, 0, 0.2);
}

.finishedMatch {
  pointer-events: none;
  cursor: not-allowed;
}

.teamText::first-letter {
  text-transform: uppercase;
}

.containerTeamWithCheckbox {
  align-items: center;
}

.teamNameRight {
  align-items: flex-end;
  width: -webkit-fill-available;
}

.teamLeft {
  width: -webkit-fill-available;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.teamRight {
  width: -webkit-fill-available;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.resultNumber {
  font-weight: bold;
  font-size: var(--xl);
  color: rgb(213, 213, 213);
}

.checkboxVisitor {
  align-self: flex-end;
}

.containerTeamRigth {
  justify-content: flex-end;
}

.buttonsCont {
  font-size: var(--l);
  line-height: var(--l);

  gap: 16px;
  align-items: center;
  justify-content: center;
}

.buttonPoints {
  border-radius: 16px;
  padding: 4px 16px;
  border: 1px solid var(--tertiary-text);
  cursor: pointer;
  color: var(--tertiary-text);
}

.buttonPoints:hover {
  opacity: 0.8;
  border-color: var(--primary);
  color: var(--primary);
}

.imgTeam {
  width: 40px;
}

.containerResult {
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: var(--xxl);
  line-height: var(--xxl);
  gap: 8px;
  user-select: none;
}

.exactResult {
  border: 1px dotted var(--primary);
  height: 100%;
  padding: 0px 6px;
}

.rightResult {
  border: 1px dotted var(--mid-grey);

  height: 100%;
  padding: 0px 6px;
}

.containerMiddle {
}

.containerPoints {
  position: absolute;
  right: 0px;
  bottom: 0;

  border-radius: 8px;
  padding: 4px 0px;
  width: 40px;
  color: var(--primary-text);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  font-weight: bold;
  user-select: none;

  /* Efecto de borde luminoso */
  border: 2px solid rgba(255, 255, 255, 0.2); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}

.containerPointsExact {
  border-color: var(--primary);
  color: var(--primary);
}

.containerPointsRight {
  border-color: var(--mid-grey);
}

.itemWithStatus {
  position: relative;
}

@media (max-width: 768px) {
  .containerFixtureItem {
    overflow: hidden;
    font-size: var(--s);
    width: -webkit-fill-available;
    max-width: none;
    min-width: auto;
    gap: 12px;
    padding: 6px 12px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Línea blanca tenue */
    background-color: rgba(0, 0, 0, 0.6);
  }
  .containerTeam {
    flex: 1;
    gap: 8px;
    justify-content: flex-start;
  }

  .containerVs {
    width: 50px;
  }
  .containerStatus {
    font-size: var(--xs);
    line-height: var(--xs);

    gap: 8px;
  }
  .teamText {
    align-self: center;
    white-space: nowrap;
  }

  .container {
    width: -webkit-fill-available;
  }
  .teamLeft {
    gap: 6px;
  }
  .teamRight {
    align-items: center;
    gap: 6px;
  }
  .teamTextRigth {
    width: auto;
    text-align: end;
  }
  .imgTeam {
    width: 40px;
  }

  .buttonsCont {
    font-size: var(--m);
    gap: 8px;
  }
  .buttonPoints {
    border-radius: 12px;
    padding: 4px 12px;

    font-size: 12px;
  }

  .itemWithStatus {
    width: -webkit-fill-available;
  }

  .containerResult {
    height: 100%;
    align-items: flex-start;
    font-size: var(--m);
    line-height: var(--m);
  }

  .exactResult {
    border: 2px solid var(--primary);
    padding: 0px 6px;
    height: auto;
    margin-bottom: 12px;
  }
  .rightResult {
    padding: 0px 6px;
    height: auto;
    margin-bottom: 12px;
  }

  .containerStatus {
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Línea blanca tenue */
  }
}
