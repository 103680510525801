.checkboxItem {
  cursor: pointer;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.checkbox {
  gap: 8px;
}
.checkbox:hover {
  opacity: 0.8;
}
