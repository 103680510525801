.card {
  color: var(--primary-text);
  border-radius: 12px;
  padding: var(--xl);
  font-size: var(--l);
  width: fit-content;
  align-items: center;
  background: linear-gradient(145deg, #121212, #1c1c1c);
  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}
